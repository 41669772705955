<script lang="ts">
  import Icon from '@iconify/svelte';
  import { getIconForActivityType } from '$lib/config/crm-config';
  import { cn } from '$lib/utils';

  interface $$Props {
    type: string;
    size?: 'sm' | 'md' | 'lg';
    class?: string;
  }

  export let type: $$Props['type'];
  export let size: $$Props['size'] = 'md';
  export let className: $$Props['class'] = undefined;

  const sizeClasses = {
    sm: 'size-6',
    md: 'size-8',
    lg: 'size-10',
  };

  $: icon = getIconForActivityType(type);
  $: sizeClass = sizeClasses[size];
</script>

<Icon
  {icon}
  class={cn(
    'text-primary-foreground',
    sizeClass,
    className,
    size === 'sm' && 'size-3',
    size === 'md' && 'size-4',
    size === 'lg' && 'size-5',
  )}
/>
