<script lang="ts">
  import { slide } from 'svelte/transition';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import Activities from '$lib/pages/activities/activities.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import UsersSelector from './../../widgets/users/users-selector.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext/hanta-input-textarea-richtext.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import { Label } from '$lib/components/ui/label';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import * as Tabs from '$lib/components/ui/tabs';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { getItems } from '$lib/api/queries';

  import { saveContract } from '$lib/api/mutations';
  import {
    type ActivityWithRefs,
    insertContractsSchema,
    type ContractWithRefs,
  } from '$db/schema';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import ContactsSelector from '$lib/widgets/contacts/contacts-selector.svelte';
  import { onMount } from 'svelte';
  import CrmObjectSelector from '$lib/widgets/crm-object-selector.svelte';
  import Prices from '../prices/prices.svelte';

  import { prices, getPrices } from './contract-prices';
  import { formatDateForPrint } from '$lib/app/utils';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  const moduleName = 'contracts';
  export let item: ContractWithRefs;

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<ContractWithRefs> =>
      Promise.resolve(saveContract(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: [moduleName] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: [moduleName, item.id] });
      const previousItems = client.getQueryData<ContractWithRefs[]>([
        moduleName,
        item.id,
      ]);

      client.setQueryData([moduleName, item.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData([moduleName], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: [moduleName, item.id] });
    },
  });

  const form = superForm<ContractWithRefs>(
    {
      ...item,
      customFields: item.customFields || {},
    },
    {
      validators: zodClient(insertContractsSchema),
      resetForm: false,
      SPA: true,
      dataType: 'json',
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }

        console.debug('Form data', $formData);

        $addMutation.mutate($formData, {
          onError(error) {
            console.error('Error', error);
          },
          onSuccess: contract => {},
        });
      },
    },
  );

  const { form: formData, errors, enhance } = form;

  let countries = [];

  let currentTab = 'details';

  $: printData = {
    ...$formData,
    countriesTnt: countries
      ?.filter(
        el => $formData.customFields?.zonesTnt?.indexOf(el.zone + '') > 0,
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
    countriesFedEx: countries
      ?.filter(
        el => $formData.customFields?.zonesFedex?.indexOf(el.zoneFedEx) > 0,
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
    contractPrices,
    date: formatDateForPrint($formData.issuedOn),
  };

  let contractPrices = {};
  onMount(() => {
    getPrices({
      baseTarif: item.baseTariffObj,
      customerTarif: item.customerTariffObj,
    }).then(result => {
      contractPrices = result;
    });
    getItems({
      collection: 'lovs',
      from: 0,
      to: 1,
      search: '',
      select: 'id,name,values',
      filters: [
        {
          field: 'name',
          operator: 'eq',
          value: 'Countries',
          active: true,
        },
      ],
    }).then(result => {
      countries = result.data[0].values?.map(el => ({
        ...el,
        name: el.name.toUpperCase(),
      }));
    });
  });

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [],
    deals: [],
    candidates: [],
    contacts: [],
    contracts: [
      {
        id: item?.id,
        name: item?.contractNo,
      },
    ],
  };
</script>

<PageWithTitle>
  <div slot="card" class="w-full">
    <div class="flex space-x-4">
      <DocxTemplates
        class="px-4 py-2"
        data={printData}
        label="Contract (RO)"
        templateName="contract_ro.docx"
        customFilename={$formData.refId}
      />
      <DocxTemplates
        class="px-4 py-2"
        data={printData}
        label="Contract (RU)"
        templateName="contract_ru.docx"
        customFilename={$formData.refId}
      />
    </div>
  </div>
  <div slot="short-card" class="p-4 w-full">
    <HantaInputText {form} name="name" label="Contract No" hideLabel={true} />
  </div>

  <div slot="content" class="grid grid-cols-3 p-1">
    <div class="col-span-3">
      <div class="mt-4">
        <Tabs.Root bind:value={currentTab} class="w-full">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>

            <Tabs.Trigger value="files">Files</Tabs.Trigger>
            <Tabs.Trigger value="prices">Prices</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="prices">
            {#if currentTab === 'prices'}
              <Prices
                customerTariff={$formData.customerTariffObj?.id}
                baseTariff={$formData.baseTariffObj?.id}
                refDate={new Date()}
              />
            {/if}
          </Tabs.Content>
          <Tabs.Content value="files">
            <div class="my-4">
              <Label>Files</Label>
              <Files
                folder={`contracts/${item?.id}`}
                compact={true}
                title=""
                maxColumns={1}
                uploadOnEnd={true}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="details">
            <div class="grid grid-cols-3 gap-4 mt-4">
              <div class="grid grid-cols-2 col-span-3 gap-4">
                <HantaInputLovSelect
                  {form}
                  bind:value={$formData.customFields['zonesTnt']}
                  label="Zones TNT"
                  type="Zones TNT"
                  multiple={true}
                />
                <HantaInputLovSelect
                  {form}
                  bind:value={$formData.customFields['zonesFedex']}
                  label="Zones FedEx"
                  type="Zones FedEx"
                  multiple={true}
                />
              </div>
              <!--
              <HantaInputNumber
                {form}
                name="customFields.paymentDelay"
                label="Payment Delay"
              />
              -->
              <HantaInputNumber {form} name="customFields.tntnr" label="TNT" />
              <HantaInputNumber
                {form}
                name="customFields.fedexnr"
                label="FedEx"
              />
              <HantaInputNumber
                {form}
                name="customFields.nrfedexexport"
                label="FedEx Export Appendix Number"
              />
            </div>

            <div class="col-span-3 border-t">
              <Activities
                id={item?.id}
                module={'contracts.docx'}
                type="all"
                withToolbar={true}
                withTabs={false}
                {activity}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="description"></Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  </div>
  <div slot="sidebar">
    <form method="POST" use:enhance class="p-4 space-y-3">
      <HantaInputText {form} name="refId" label="ID" />
      <HantaInputText {form} name="name" label="Contract No" />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.status}
        type="Contract > Status"
        label="Status"
        multiple={false}
      />
      <AccountSelector
        bind:value={$formData.account}
        name="accountId"
        label="Account"
      />
      {#key $formData.account?.id}
        <ContactsSelector
          accountId={$formData.account?.id}
          bind:value={$formData.contact}
        />
      {/key}

      <HantaInputDate {form} name="issuedOn" label="Date" />
      <div class="grid grid-cols-2 gap-2">
        <HantaInputDate {form} name="validFrom" label="Valid from" />
        <HantaInputDate {form} name="validTo" label="Valid to" />
      </div>

      <CrmObjectSelector
        module="tariffs"
        bind:value={$formData.baseTariffObj}
        label="Base tariff"
      />
      <CrmObjectSelector
        module="tariffs"
        bind:value={$formData.customerTariffObj}
        label="Customer tariff"
      />

      <UsersSelector
        bind:value={$formData.sales}
        name="sales"
        label="Sales"
        multiple={false}
      />

      <HantaInputTextareaRichtext {form} name="description" rows={10} />

      <FormActions {form} />
    </form>
  </div>
</PageWithTitle>
