<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { cn } from '$lib/utils';
  import { getPipelines } from '$lib/api/pipeline-api';
  import Button from '$lib/components/ui/button/button.svelte';
  import { appStore } from '$lib/stores/app-store';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import Icon from '@iconify/svelte';
  import CreatePipeline from '$lib/components/pipelines/create-pipeline.svelte';

  import { useQueryClient } from '@tanstack/svelte-query';

  const queryClient = useQueryClient();

  const moduleIcons = {
    accounts: 'mdi:account',
    deals: 'mdi:handshake',
    activities: 'mdi:calendar',
    files: 'mdi:file',
    contacts: 'mdi:contact',
    default: 'mdi:pipeline',
  };

  const pipelinesQuery = createQuery({
    queryKey: ['pipelines'],
    queryFn: () => getPipelines(),
  });

  function getModuleIcon(module: string) {
    return (
      moduleIcons[module as keyof typeof moduleIcons] || moduleIcons.default
    );
  }

  function onCreatePipeline() {
    queryClient.invalidateQueries({ queryKey: ['pipelines'] });
  }
</script>

{#if $appStore.isCollapsed}
  <div class="my-2">
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        class="flex gap-2 items-center px-2 py-1.5 w-full text-sm rounded-md hover:bg-muted"
      >
        <Icon icon="ph:kanban-fill" class="ml-2 text-primary size-5" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content class="w-72 z-[99999]" side="right" align="start">
        {#if $pipelinesQuery.isLoading}
          <DropdownMenu.Item>Loading...</DropdownMenu.Item>
        {:else if $pipelinesQuery.isError}
          <DropdownMenu.Item class="text-destructive"
            >Error loading pipelines</DropdownMenu.Item
          >
        {:else if $pipelinesQuery.data?.length === 0}
          <DropdownMenu.Item class="text-muted-foreground"
            >No pipelines configured</DropdownMenu.Item
          >
        {:else}
          {#each $pipelinesQuery.data || [] as pipeline}
            <DropdownMenu.Item
              class={cn(
                $appStore?.selected?.id === pipeline.id
                  ? 'bg-primary text-white'
                  : '',
              )}
            >
              <button
                class={cn('flex items-center w-full gap-2')}
                on:click={() => {
                  appStore.select({
                    id: pipeline.id,
                    module: 'pipelines',
                  });
                  appStore.openPopup(true);
                }}
              >
                <Icon icon={getModuleIcon(pipeline.module)} class="size-4" />
                <div class="w-full text-left truncate">
                  {pipeline.name}
                </div>
                {#if pipeline.modifySource}
                  <Icon
                    icon="mdi:pencil"
                    class={cn(
                      'size-4',
                      $appStore?.selected?.id === pipeline.id
                        ? 'text-white'
                        : 'text-muted-foreground',
                    )}
                  />
                {/if}
              </button>
            </DropdownMenu.Item>
          {/each}
        {/if}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  </div>
{:else}
  <div class="my-4 space-y-1">
    <div
      class="flex justify-between items-center px-2 py-1.5 text-sm font-semibold"
    >
      <Icon icon="ph:kanban-fill" class="ml-2 text-primary size-5" />
      <span>Pipelines</span>

      <CreatePipeline onCreate={onCreatePipeline} />
    </div>

    {#if $pipelinesQuery.isLoading}
      <div class="px-2 py-1 text-sm text-muted-foreground">Loading...</div>
    {:else if $pipelinesQuery.isError}
      <div class="px-2 py-1 text-sm text-destructive">
        Error loading pipelines
      </div>
    {:else if $pipelinesQuery.data?.length === 0}
      <div class="px-2 py-1 text-sm text-muted-foreground">
        No pipelines configured
      </div>
    {:else}
      <nav class="space-y-1">
        {#each $pipelinesQuery.data || [] as pipeline}
          <Button
            variant="ghost"
            class={cn(
              'w-full justify-start',
              $appStore?.selected?.id === pipeline.id
                ? 'bg-primary text-white'
                : '',
            )}
            on:click={() => {
              appStore.select({
                id: pipeline.id,
                module: 'pipelines',
              });
              appStore.openPopup(true);
            }}
          >
            <Icon icon={getModuleIcon(pipeline.module)} class="mr-2 size-5" />
            <div class="w-full text-left truncate">
              {pipeline.name}
            </div>
            {#if pipeline.modifySource}
              <Icon
                icon="mdi:pencil"
                class={cn(
                  'size-4',
                  $appStore?.selected?.id === pipeline.id
                    ? 'text-white'
                    : 'text-muted-foreground',
                )}
              />
            {/if}
          </Button>
        {/each}
      </nav>
    {/if}
  </div>
{/if}
