<script lang="ts">
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input, type Variant } from '$lib/components/ui/input/index';
  import { cn } from '$lib/utils';
  import Icon from '@iconify/svelte';
  import { stringProxy } from 'sveltekit-superforms';

  export let form;
  export let icon: any;
  export let name;
  export let variant: Variant = 'hanta';
  export let label = undefined;
  export let placeholder = undefined;
  export let description = '';
  export let readOnly = false;
  export let isLink = false;
  export let hideLabel = false;

  type $$Props = {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    variant?: Variant;
    readOnly?: boolean;
    isLink?: boolean;
    icon?: any;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  const { form: formData } = form;

  $: proxy = stringProxy(formData, name, { empty: 'undefined' });
</script>

<Form.Field class={cn('space-y-1', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label && !hideLabel}
      <Form.Label>
        <HantaIcon {icon} />

        {label}
        {#if isLink && $proxy}
          <a href={$proxy} class="ml-2" target="_blank">
            <Icon icon="mdi:square-arrow-out-up-right" class="inline size-4" />
          </a>
        {/if}
      </Form.Label>
    {/if}
    {#if readOnly}
      <div
        class="inline-flex items-center p-0 w-full h-7 text-sm font-medium whitespace-nowrap bg-transparent rounded-md border-none ring-0 transition-colors outline-none items-left ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-muted hover:outline-none hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-b focus:bg-secondary"
      >
        {$proxy}
      </div>
    {:else}
      <Input
        {...attrs}
        bind:value={$proxy}
        class={cn('w-full', className)}
        {variant}
        {placeholder}
      />
    {/if}
  </Form.Control>
  <Form.Description>{description ?? ''}</Form.Description>
  <Form.FieldErrors />
</Form.Field>
