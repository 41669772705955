<script lang="ts">
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils';
  import HantaInputMultiSelectLean from './../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { Button } from '$lib/components/ui/button';
  import { getItems } from '$lib/api/queries';

  import { currentUserStore } from '$lib/stores/current-user-store';

  export let hideLabel = false;
  export let label = 'User';
  export let value = undefined;
  export let onChange = undefined;
  export let multiple = false;
  export let readOnly = false;

  export let className = '';

  export { className as class };

  onMount(async () => {
    console.debug('users selector', value);
  });

  let options = [];
  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,photo',
    });

    options = result.data;
    return result.data;
  };

  const setToCurrentUser = () => {
    const user = options?.find(v => v.name === $currentUserStore?.name);
    if (user) value = user;
  };
</script>

<HantaInputMultiSelectLean
  class={cn('w-full', className)}
  bind:value
  label={hideLabel ? '' : label}
  {loadOptions}
  {multiple}
  onSelectChanged={value => onChange && onChange(value)}
  placeholder="Select user..."
  {readOnly}
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.photo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex overflow-hidden items-center w-full h-full"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.photo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 w-full text-sm truncate">
      {selection?.name}
    </div>
  </div>
  <div slot="addon">
    {#if value?.name !== $currentUserStore?.name}
      <button on:click={setToCurrentUser} class="text-xs text-primary"
        >Set to me</button
      >
    {/if}
  </div>
</HantaInputMultiSelectLean>
