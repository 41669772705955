<script>
  import { onDestroy, onMount } from 'svelte';
  import { authStore } from '$lib/stores/auth-store';
  import { appStore } from '$lib/stores/app-store';
  import { cn } from '$lib/utils';
  import { dark, light } from '@clerk/themes';
  import { clerkStore } from './clerk-store';
  let userButtonComponent;
  let isLightTheme = true;

  onMount(async () => {
    clerkStore.subscribe(({ clerk }) => {
      clerk?.mountUserButton(userButtonComponent, {
        appearance: {
          baseTheme: isLightTheme ? light : dark,
        },
      });
    });
  });

  onDestroy(() => {
    clerkStore.subscribe(({ clerk }) => {
      clerk?.unmountUserButton(userButtonComponent);
    });
  });
</script>

<div class="flex items-center pl-1 space-x-2">
  <div id="user-button" bind:this={userButtonComponent} />
  <div
    class={cn(
      'text-base  text-muted-foreground',
      $appStore.isCollapsed ? 'hidden' : 'block',
    )}
  >
    <div>
      {$authStore?.user?.firstName}
      {$authStore?.user?.lastName}
    </div>
  </div>
</div>

<style>
  :global(#global-nav) {
    z-index: 10;
  }
</style>
