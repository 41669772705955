<script lang="ts">
  import Select from 'svelte-select';
  import { cn } from '$lib/utils';
  import Label from '$lib/components/ui/label/label.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { tick } from 'svelte';

  export let value = undefined;

  export let readOnly = false;
  export let label = undefined;
  export let description = '';
  export let placeholder = '';
  export let simpleMode = false; // if true, the value is a simple array of strings
  export let multiple = true; // if true, the value is an array
  export let options = []; // if provided, the options are static
  export let disabled = false;
  export let asArray = false;
  export let addable = false;
  export let loadOptions: ((keyword: string) => any) | undefined = () => [];
  export let onSelectChanged = () => {};
  export let onDropdownOpen = () => {};
  export let onItemAdded: ((item: any) => any) | undefined = undefined;

  let items: any[];
  let svelteSelect: Select;
  let isOpen = false;
  let filterText = '';
  let filterChanged = false;

  type $$Props = {
    value: any;
    readOnly?: boolean;
    variant?: 'default' | 'light';
    label?: string;
    description?: string;
    placeholder?: string;
    simpleMode?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    options?: any[];
    class?: string;
    asArray?: boolean;
    addable?: boolean;
    loadOptions?: ((keyword: string) => any) | undefined;
    onSelectChanged?: (e) => void;
    onDropdownOpen?: () => void;
    onItemAdded?: (item: any) => void;
  };

  let className: $$Props['class'] = 'w-full';
  export { className as class };

  $: if (isOpen) {
    onDropdownOpen && onDropdownOpen();
  }

  const processValue = item => {
    if (simpleMode) {
      if (typeof item === 'string' && item.startsWith('Add: ')) {
        const newValue = item.replace('Add: ', '');
        console.debug('Processing new item:', newValue);
        return newValue;
      }
      if (typeof item === 'object') {
        const itemValue = item.value;
        if (typeof itemValue === 'string' && itemValue.startsWith('Add: ')) {
          const newValue = itemValue.replace('Add: ', '');
          console.debug('Processing new object item:', newValue);
          return newValue;
        }
        return itemValue;
      }
      return item;
    }
    return item.value || item;
  };

  const onSelectChangedFn = async e => {
    console.debug('Select changed:', e.detail);

    // Check if we're adding a new item
    const details = Array.isArray(e.detail) ? e.detail : [e.detail];
    const addingNewItem = details.some(item => {
      if (typeof item === 'string') {
        return item.startsWith('Add: ');
      }
      if (typeof item?.value === 'string') {
        return item.value.startsWith('Add: ');
      }
      return false;
    });

    if (addingNewItem && onItemAdded) {
      const newItem = details.find(item => {
        if (typeof item === 'string') {
          return item.startsWith('Add: ');
        }
        if (typeof item?.value === 'string') {
          return item.value.startsWith('Add: ');
        }
        return false;
      });
      const rawValue = typeof newItem === 'string' ? newItem : newItem.value;
      const newValue = rawValue.replace('Add: ', '');
      console.debug('Calling onItemAdded with:', newValue);
      onItemAdded(newValue);
    }

    // Process the values
    const processedValue = multiple
      ? e.detail.map(processValue)
      : asArray
        ? [processValue(e.detail)]
        : processValue(e.detail);

    // Update the value
    value = processedValue;

    // Update selectability
    await tick();
    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    onSelectChanged?.(value);
  };

  const onSelectClear = async e => {
    const vals = e.detail;

    if (Array.isArray(vals)) {
      value = value?.filter(a => !vals.find(v => v.id === a.id));
    } else {
      const valToCompare = vals.id || vals.value || vals;
      value =
        multiple || asArray
          ? value?.filter(a => {
              const aToCompare = a.id || a.value || a;
              return aToCompare !== valToCompare;
            })
          : undefined;
    }

    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    console.debug('onSelectChanged accounts removed', value);
    onSelectChanged(undefined);
  };

  function isSelected(item) {
    return multiple || asArray
      ? !value?.find(a => a.id === item.id)
      : value !== item.id;
  }

  const handleFilter = async e => {
    if (!addable || !(simpleMode && multiple)) return;

    if (filterChanged) {
      filterChanged = false;
      return;
    }

    const prevOptions = options.filter((v: string) => !v.startsWith('Add:'));
    const matchingOption = e.detail.some(
      v =>
        v.value?.toLowerCase() === filterText.toLowerCase() ||
        v.toLowerCase?.() === filterText.toLowerCase(),
    );

    if (matchingOption) {
      options = prevOptions;
    } else {
      options =
        filterText.length > 0
          ? ['Add: ' + filterText, ...prevOptions]
          : prevOptions;
    }

    filterChanged = true;
  };

  const handleBlur = async () => {
    if (simpleMode && multiple) {
      filterText = '';
      options = options.filter((v: string) => !v.startsWith('Add: '));
    }
  };

  $: loadOptionsInternal = async query => {
    if (options && options.length > 0) {
      return options;
    }

    if (!loadOptions) return [];

    let result = await loadOptions(query);
    return result?.map(item => ({
      ...item,
      value: item,
      selectable: isSelected(item),
    }));
  };

  $: value = multiple
    ? value?.map(a => (simpleMode ? a : { value: a, ...a })) || []
    : asArray
      ? (value && value[0]) || undefined
      : value;
</script>

<div class={cn('grid-cols-1 grid-rows-1 gap-y-2 grid', className)}>
  {#if label}
    <Label class="flex flex-row gap-2 items-center">
      <slot name="icon" />
      {label}
      {#if $$slots.addon}
        <slot name="addon" />
      {/if}
    </Label>
  {/if}
  {#if !readOnly}
    <Select
      bind:this={svelteSelect}
      bind:filterText
      {disabled}
      {addable}
      items={options && options.length > 0 ? options : items}
      itemId="value"
      bind:listOpen={isOpen}
      {multiple}
      loadOptions={simpleMode ? undefined : loadOptionsInternal}
      on:change={onSelectChangedFn}
      on:clear={onSelectClear}
      on:filter={handleFilter}
      on:blur={handleBlur}
      placeholder={placeholder || label}
      {value}
    >
      <svelte:fragment let:index let:item slot="item">
        {#if $$slots.item}
          <slot {index} {item} name="item" />
        {:else}
          <div class="flex items-center h-full">
            <div class="text-sm">{item?.label || item}</div>
          </div>
        {/if}
      </svelte:fragment>

      <svelte:fragment let:selection slot="selection">
        {#if $$slots.selection}
          <slot name="selection" {selection} />
        {:else}
          <div class="flex items-center h-full">
            <div class="ml-2 text-sm">{selection?.label || selection}</div>
          </div>
        {/if}
      </svelte:fragment>
    </Select>
  {:else if $$slots.selection}
    <slot name="selection" selection={value} />
  {:else}
    <div class="flex items-center h-full">
      {#each Array.isArray(value) ? value : [value] as item, index}
        <Badge class="ml-2 text-xs truncate">{item || 'n/a'}</Badge>
      {/each}
    </div>
  {/if}

  {#if description}
    <div>{description ?? ''}</div>
  {/if}
</div>

<style lang="postcss">
  :global(.svelte-select .value-container) {
    flex-shrink: 0;
  }

  :global(.svelte-select-list) {
    @apply !bg-primary-foreground;
  }

  :global(.svelte-select) {
    --item-is-active-bg: theme(colors.primary.DEFAULT);
    --item-is-active-color: theme(colors.secondary.DEFAULT);
    border: solid 1px theme(colors.gray.200) !important;
    @apply !border-primary/20 hover:!bg-muted transition-all !px-2 shrink-0 border border-muted hover:border-primary/20;
    --multi-select-padding: 0;
    --multi-select-border: solid 1px theme(colors.gray.200);
    --border-hover: solid 1px theme(colors.gray.200);
    --border: solid 1px theme(colors.gray.200);
    --border-radius: 3px;
    --multi-item-border-radius: 3px;
    --multi-item-bg: theme(colors.muted.DEFAULT);
    --item-hover-bg: theme(colors.muted.DEFAULT);
    --multi-item-clear-icon-color: theme(colors.primary.DEFAULT);
  }

  :global(.svelte-select input::placeholder) {
    @apply px-2;
  }

  :global(.svelte-select input:focus, .svelte-select input:hover) {
    box-shadow: unset;
  }

  :global(.svelte-select input) {
    --tw-ring-shadow: 0 0 #000 !important;
    --placeholder-color: theme(colors.primary.DEFAULT);
    @apply !text-sm font-medium !text-primary;
    box-shadow: unset;
  }

  :global(.svelte-select:hover) {
    border: none;
  }
</style>
