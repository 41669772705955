<script lang="ts">
  import { type ActivityWithRefs, type ContactWithRefs } from '$db/schema';

  import * as Avatar from '$lib/components/ui/avatar';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/stores/app-store';

  export let activity: ActivityWithRefs;
  export let crmContact: ContactWithRefs;
</script>

<Avatar.Root>
  <Avatar.Image
    alt="Contact Photo"
    class="object-scale-down"
    src={crmContact?.photo}
  />
  <Avatar.Fallback>{crmContact?.name ?? ''}</Avatar.Fallback>
</Avatar.Root>

<div class="flex flex-col w-full">
  <h2 class="text-sm font-bold">
    {crmContact?.name ?? ''}
  </h2>
  <div class="text-sm text-muted-foreground">
    {crmContact?.position ?? ''}
  </div>
</div>

<div class={cn($appStore.isMobile ? 'hidden' : 'absolute right-2')}>
  <ActivityActionBar {activity} mode="collapsed" />
</div>
