<script lang="ts">
  import * as Card from '$lib/components/ui/card';

  import { addContactToDeal, createItem } from '$lib/api/mutations';
  import { createTable, Subscribe, Render } from 'svelte-headless-table';
  import {
    addSortBy,
    addPagination,
    addTableFilter,
    addSelectedRows,
    addHiddenColumns,
  } from 'svelte-headless-table/plugins';
  import { readable } from 'svelte/store';
  import * as Table from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import { cn } from '$lib/utils.js';
  import { Input } from '$lib/components/ui/input';
  import Icon from '@iconify/svelte';
  import { type Deal } from '$db/schema';
  import { appStore } from '$lib/stores/app-store';
  import SelectExistingDeal from './select-existing-deal.svelte';

  export let label = undefined;
  export let contactId: string | undefined = undefined;
  export let customerId: string | undefined = undefined;
  export let deals: Deal[];

  const table = createTable(readable(deals), {
    sort: addSortBy({ disableMultiSort: true }),
    page: addPagination(),
    filter: addTableFilter({
      fn: ({ filterValue, value }) => value.includes(filterValue),
    }),
    select: addSelectedRows(),
    hide: addHiddenColumns(),
  });

  const columns = table.createColumns([
    /*table.column({
      header: (_, { pluginStates }) => {
        const { allPageRowsSelected } = pluginStates.select;
        return createRender(DataTableCheckbox, {
          checked: allPageRowsSelected,
        });
      },
      accessor: 'id',
      cell: ({ row }, { pluginStates }) => {
        const { getRowState } = pluginStates.select;
        const { isSelected } = getRowState(row);

        return createRender(DataTableCheckbox, {
          checked: isSelected,
        });
      },
      plugins: {
        sort: {
          disable: true,
        },
        filter: {
          exclude: true,
        },
      },
    }),
    */

    table.column({
      header: 'Name',
      accessor: 'name',
      cell: ({ value }) => value ?? 'N/A',
      plugins: {
        filter: {
          getFilterValue(value) {
            return value;
          },
        },
      },
    }),

    table.column({
      header: 'Status',
      accessor: 'state',
      cell: ({ value }) => value ?? 'N/A',
      plugins: { sort: { disable: false }, filter: { exclude: false } },
    }),
    table.column({
      header: 'Total Fee',
      accessor: 'totalFee',
      cell: ({ value }) => {
        const formatted = new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'EUR',
        }).format(value);
        return formatted;
      },
      plugins: {
        sort: {
          disable: false,
        },
        filter: {
          exclude: true,
        },
      },
    }),
    /*
    table.column({
      header: '',
      accessor: ({ id }) => id,
      cell: item => {
        return createRender(Actions, { id: item.value });
      },
      plugins: {
        sort: {
          disable: true,
        },
      },
    }),*/
  ]);

  const {
    headerRows,
    pageRows,
    tableAttrs,
    tableBodyAttrs,
    flatColumns,
    pluginStates,
    rows,
  } = table.createViewModel(columns);

  const { sortKeys } = pluginStates.sort;

  const { hiddenColumnIds } = pluginStates.hide;
  const ids = flatColumns.map(c => c.id);
  let hideForId = Object.fromEntries(ids.map(id => [id, true]));

  $: $hiddenColumnIds = Object.entries(hideForId)
    .filter(([, hide]) => !hide)
    .map(([id]) => id);

  const { hasNextPage, hasPreviousPage, pageIndex } = pluginStates.page;
  const { filterValue } = pluginStates.filter;

  const { selectedDataIds } = pluginStates.select;

  const hideableCols = ['state', 'name', 'totalFee'];

  async function createNewDeal() {
    const data = await createItem('deals', {
      name: 'New deal',
      state: 'open',
      customer: customerId,
      totalFee: 0,
    });
    if (contactId) {
      await addContactToDeal({
        contactId: contactId,
        dealId: data[0].id,
      });
    }

    appStore.select({
      module: 'deals',
      id: data[0].id,
    });
    appStore.openPopup(true);
  }
</script>

<Card.Root>
  <Card.Header>
    <Card.Title>
      <div class="flex justify-between">
        <h2>
          {label ?? 'Deals'}
          <span class="text-base text-muted-foreground">({deals?.length})</span>
        </h2>
        <div>
          <Button class="w-32" variant="secondary" on:click={createNewDeal}
            >Add new deal</Button
          >
          {#if contactId}
            <SelectExistingDeal {customerId} {contactId} />
          {/if}
        </div>
      </div>
    </Card.Title>
    <Card.Description></Card.Description>
  </Card.Header>
  <Card.Content>
    {#if deals?.length === 0}
      <p class="mt-2 text-sm text-gray-500">No deals found</p>
    {:else}
      <div class="w-full">
        <div class="flex gap-4 items-center mb-4">
          <Input
            class="pl-2 max-w-sm"
            placeholder="Filter deals"
            type="text"
            bind:value={$filterValue}
          />
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild let:builder>
              <Button variant="outline" class="ml-auto" builders={[builder]}>
                Columns <Icon icon="mdi:chevron-down" class="ml-2 w-4 h-4" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {#each flatColumns || [] as col}
                {#if hideableCols.includes(col.id)}
                  <DropdownMenu.CheckboxItem bind:checked={hideForId[col.id]}>
                    {col.header}
                  </DropdownMenu.CheckboxItem>
                {/if}
              {/each}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
        <div class="rounded-md border">
          <Table.Root {...$tableAttrs}>
            <Table.Header>
              {#each $headerRows || [] as headerRow}
                <Subscribe rowAttrs={headerRow.attrs()}>
                  <Table.Row>
                    {#each headerRow.cells || [] as cell (cell.id)}
                      <Subscribe
                        attrs={cell.attrs()}
                        let:attrs
                        props={cell.props()}
                        let:props
                      >
                        <Table.Head
                          {...attrs}
                          class={cn('[&:has([role=checkbox])]:pl-3')}
                        >
                          {#if cell.id === 'totalFee1'}
                            <div class="text-right">
                              <Render of={cell.render()} />
                            </div>
                          {:else if cell.id === 'name' || cell.id === 'state' || cell.id === 'totalFee'}
                            <Button
                              variant="ghost"
                              on:click={props.sort.toggle}
                            >
                              <Render of={cell.render()} />
                              {@const sort = $sortKeys?.find(
                                a => a.id === cell.id,
                              )}
                              {#if sort}
                                <Icon
                                  icon="mdi:caret-up"
                                  class={cn(
                                    'ml-2 h-4 w-4 text-primary',
                                    sort.order === 'desc' &&
                                      'transform rotate-180',
                                  )}
                                />
                              {:else}
                                <Icon
                                  icon="mdi:caret-sort"
                                  class={cn(
                                    $sortKeys[0]?.id === cell.id &&
                                      'text-blue-800',
                                    'ml-2 h-4 w-4',
                                  )}
                                />
                              {/if}
                            </Button>
                          {:else}
                            <Render of={cell.render()} />
                          {/if}
                        </Table.Head>
                      </Subscribe>
                    {/each}
                  </Table.Row>
                </Subscribe>
              {/each}
            </Table.Header>
            <Table.Body {...$tableBodyAttrs}>
              {#each $pageRows || [] as row (row.id)}
                <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
                  <Table.Row
                    {...rowAttrs}
                    data-state={$selectedDataIds[row.id] && 'selected'}
                  >
                    {#each row.cells || [] as cell (cell.id)}
                      <Subscribe attrs={cell.attrs()} let:attrs>
                        <Table.Cell
                          class="[&:has([role=checkbox])]:pl-3"
                          {...attrs}
                        >
                          {#if cell.id === 'totalFee'}
                            <div class="font-medium text-right">
                              <Render of={cell.render()} />
                            </div>
                          {:else if cell.id === 'name'}
                            <Button
                              variant="link"
                              on:click={() => {
                                appStore.select({
                                  module: 'deals',
                                  id: row?.original?.id,
                                });
                                appStore.openPopup(true);
                              }}
                            >
                              <Render of={cell.render()} />
                            </Button>
                          {:else}
                            <Render of={cell.render()} />
                          {/if}
                        </Table.Cell>
                      </Subscribe>
                    {/each}
                  </Table.Row>
                </Subscribe>
              {/each}
            </Table.Body>
          </Table.Root>
        </div>
        <div class="flex justify-end items-center py-4 space-x-2">
          <!--div class="flex-1 text-sm text-muted-foreground">
      {Object.keys($selectedDataIds).length} of{' '}
      {$rows.length} row(s) selected.
    </div-->
          <Button
            variant="outline"
            size="sm"
            on:click={() => ($pageIndex = $pageIndex - 1)}
            disabled={!$hasPreviousPage}>Previous</Button
          >
          <Button
            variant="outline"
            size="sm"
            disabled={!$hasNextPage}
            on:click={() => ($pageIndex = $pageIndex + 1)}>Next</Button
          >
        </div>
      </div>
    {/if}
  </Card.Content>
</Card.Root>
