<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import { Input } from '$lib/components/ui/input/index.js';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import Pagination from '$lib/app/list/pagination.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import * as Card from '$lib/components/ui/card';
  import type { Contact, Deal } from '$db/schema';
  import { getItems, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils';
  import { appStore, popupStore } from '$lib/stores/app-store';
  import { ChevronDown, Users } from 'lucide-svelte';
  import * as Avatar from '$lib/components/ui/avatar/index.js';
  import * as HoverCard from '$lib/components/ui/hover-card/index.js';

  type $$Props = {
    class?: string;
  };

  let deals: Deal[] = [];
  let count = 0;
  let phases = [];
  let filterValue: string = '';
  let page = 0;
  let expandedDealId: string | null = null;
  const pageSize = 100;

  $: filterValue, getDeals();
  $: page, getDeals();

  const toggleDealExpand = (dealId: string) => {
    expandedDealId = expandedDealId === dealId ? null : dealId;
  };

  const openItem = (deal: Deal) => {
    popupStore.openPopup('crmObject', {
      id: deal?.id,
      module: 'deals',
    });
  };

  const openContact = (contact: Contact) => {
    popupStore.openPopup('crmObject', {
      id: contact?.id,
      module: 'contacts',
    });
  };

  const loadDeals = async () => {
    return await getItems({
      collection: 'deals',
      from: page,
      search: filterValue,
      to: page + pageSize,
      select:
        '*,customerObj:accounts(id,name),activities:activities(id, status),keyContacts:contacts(id,name)',
      filters: [
        { field: 'state', operator: 'eq', value: 'Active', active: true },
        {
          field: 'activities.type',
          operator: 'eq',
          value: 'Assignment',
          active: true,
        },
      ],
    });
  };

  async function getDeals() {
    const res = await loadDeals();
    deals = res.data as Deal[];
    count = res.count;
  }

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
    await getDeals();
  });

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<Card.Root
  class={cn('h-full overflow-hidden bg-primary-50 flex flex-col', className)}
>
  <Card.Header
    class="border-b border-solid bg-primary-800 border-b-primary-200/20"
  >
    <Card.Title class="flex items-center gap-2">
      Current Deals
      <Badge variant="secondary" class="rounded-full">{count}</Badge>
    </Card.Title>

    <div class="pt-2">
      <Input
        bind:value={filterValue}
        class="w-full"
        placeholder="Filter deals"
        type="text"
      />
    </div>
  </Card.Header>

  <Card.Content class="flex flex-col h-full gap-2 p-6 overflow-scroll">
    {#each deals as deal}
      <div
        class="rounded-lg border bg-primary-800 border-muted hover:bg-primary-700/50 transition-colors"
      >
        <!-- Deal Header -->

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->

        <div
          class="relative flex items-center gap-2 p-4 cursor-pointer hover:bg-accent/50 transition-colors w-full"
          on:click={() => toggleDealExpand(deal.id)}
        >
          <div class="absolute right-4 top-8">
            <div class="flex items-center gap-2 justify-end">
              <div class="flex items-center gap-1 text-muted-foreground">
                <Users class="h-4 w-4" />
                <span class="text-sm font-medium text-primary"
                  >{deal.activities?.length || 0}</span
                >
              </div>

              <ChevronDown
                class="h-4 w-4 transition-transform {expandedDealId === deal.id
                  ? 'rotate-180'
                  : ''}"
              />
            </div>
          </div>
          <div class="">
            <div class=" text-xs text-muted-foreground">{deal.refId ?? ''}</div>
            <div class="text-sm">{deal.customerObj?.name}</div>
            <div class="overflow-hidden w-full">
              <CrmAvatar
                id={deal.customer}
                module="accounts"
                name={deal.name}
                asDiv={true}
              />
            </div>
          </div>
          <!--Badge variant="outline" class="bg-green-50 text-green-600">
            {deal.state}
          </Badge-->
        </div>

        <!-- Expanded Content -->
        {#if expandedDealId === deal.id}
          <div class="px-4 pb-4 pt-2">
            <!-- Deal Phase -->
            <div class="mb-6">
              <div class="flex items-center gap-0">
                {#each phases as phase, idx}
                  <HoverCard.Root>
                    <HoverCard.Trigger
                      rel="noreferrer noopener"
                      class="flex justify-center bg-red-400/10 border p-2 w-12 items-center rounded-sm underline-offset-4 hover:bg-red-400/20 transition-colors focus-visible:outline-2 focus-visible:outline-offset-8 focus-visible:outline-black"
                    >
                      <span class="text-xs">
                        {(deal.activities || []).filter(
                          el => el.status === phase,
                        ).length}
                      </span>
                    </HoverCard.Trigger>
                    <HoverCard.Content class="w-36">
                      <div class="flex justify-between space-x-4">
                        <div class="space-y-1">
                          <h4 class="text-sm font-semibold">{phase}</h4>
                          <p class="text-sm"></p>
                        </div>
                      </div>
                    </HoverCard.Content>
                  </HoverCard.Root>
                {/each}
              </div>
            </div>

            <!-- Contacts Section -->
            {#if deal.keyContacts && deal.keyContacts.length > 0}
              <div class="space-y-4">
                <h4 class="font-medium text-muted-foreground">Deal Contacts</h4>

                <div class="grid grid-cols-2 gap-2">
                  {#each deal.keyContacts as contact}
                    <CrmAvatar
                      id={contact.id}
                      module="contacts"
                      on:open={e => openContact(e.detail)}
                      class="hover:scale-105 transition-transform"
                    />
                  {/each}
                </div>
              </div>
            {/if}

            <Button
              variant="outline"
              on:click={() => openItem(deal)}
              class="mt-4"
            >
              View Deal
            </Button>
          </div>
        {/if}
      </div>
    {/each}
  </Card.Content>

  <Card.Footer
    class="border-t border-solid border-gray-200 bg-primary-800 shadow-sm pb-1.5 pt-1 gap-4 justify-between hidden"
  >
    <Pagination
      {count}
      from={page}
      on:next={() => (page += 10)}
      on:previous={() => (page -= 10)}
    />
  </Card.Footer>
</Card.Root>
