<script lang="ts">
  import * as Collapsible from '$lib/components/ui/collapsible/index.js';
  import { cn } from '$lib/utils';
  import type { Route } from '$lib/config';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { appStore } from '$lib/stores/app-store';
  import MenuItem from './navigation/menu-item.svelte';
  import Icon from '@iconify/svelte';
  export let routes: Route[];
</script>

{#if $appStore.isCollapsed}
  <nav>
    {#each routes || [] as route}
      <Tooltip.Root openDelay={100}>
        <Tooltip.Trigger asChild let:builder>
          <MenuItem {route} collapsed={true} />
        </Tooltip.Trigger>
        <Tooltip.Content side="right" class="flex gap-4 items-center">
          {route.title}
          {#if route.label}
            <span class="ml-auto text-muted-foreground">
              {route.label}
            </span>
          {/if}
        </Tooltip.Content>
      </Tooltip.Root>
    {/each}
  </nav>
{:else}
  {#each routes || [] as route}
    {#if route?.children?.length > 0}
      <Collapsible.Root class="w-full">
        <Collapsible.Trigger asChild let:builder>
          <Button
            builders={[builder]}
            variant="ghost"
            size="sm"
            class="flex justify-between items-center p-0 w-full"
          >
            <div class="flex items-center">
              <Icon
                icon={route.icon}
                class={cn('size-4', 'mr-2', 'ml-3')}
                aria-hidden="true"
              />

              <div class="">{route.title}</div>
            </div>

            <Icon icon="mdi:chevron-down" class="w-4 h-4" />

            <span class="sr-only">Toggle</span>
          </Button>
        </Collapsible.Trigger>

        <Collapsible.Content class="pl-4 space-y-1">
          {#each route.children as childRoute}
            <MenuItem route={childRoute} />
          {/each}
        </Collapsible.Content>
      </Collapsible.Root>
    {:else}
      <MenuItem {route} />
    {/if}
  {/each}
{/if}
