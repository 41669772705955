<script lang="ts">
  import { onMount } from 'svelte';
  import { deleteFile, listFiles } from '$lib/api/storage';
  import File from './file.svelte';
  import { appStore } from '$lib/stores/app-store';
  import { cn } from '$lib/utils';
  import { Button } from '$lib/components/ui/button';

  let files = [];

  export let singleFileMode = false;
  export let folder;
  export let refresh;
  export let noFilesFoundLabel = 'No files found';
  export let maxColumns = 3;
  export let onUpload = () => {};

  $: if (refresh) {
    listFiles(folder).then(data => {
      files = data;
    });
  }
  onMount(async () => {
    files = await listFiles(folder);
  });

  const removeFile = async file => {
    await deleteFile(folder, file);
    files = [];
    refresh = refresh + 1;
    if (onUpload) {
      setTimeout(() => {
        onUpload();
      }, 5000);
    }
  };
</script>

{#if files.length === 0}
  <p class="mt-6 text-md">{noFilesFoundLabel}</p>
{:else if singleFileMode}
  {#each files as file}
    <Button variant="destructive" on:click={() => removeFile(file.name)}
      >Delete file</Button
    >
  {/each}
{:else}
  <div
    class={cn(
      'gap-2 py-2 tn-grid',
      $appStore.isMobile ? 'grid-cols-1' : `grid-cols-${maxColumns}`,
    )}
  >
    {#each files as file}
      <File {file} {folder} bind:refresh></File>
    {/each}
  </div>
{/if}
