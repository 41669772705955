<script lang="ts">
  import Icon from '@iconify/svelte';
  import { Badge } from '$lib/components/ui/badge';
  import { formatDate } from '$lib/app/utils';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import { findOpenInvoices, getItemById } from '$lib/api/queries';
  import { createItem } from '$lib/api/mutations.js';
  import { appStore, contextStore } from '$lib/stores/app-store';
  import { Button, buttonVariants } from '$lib/components/ui/button/index.js';
  import * as Dialog from '$lib/components/ui/dialog/index.js';
  import { Input } from '$lib/components/ui/input/index.js';
  import { Label } from '$lib/components/ui/label/index.js';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import {
    finalizeInvoice,
    saveInvoice,
    updateInvoiceStatus,
  } from '$lib/api/invoice-api';

  export let form;
  let { form: formData, enhance, errors } = form;

  export let readOnly;
  export let createOrder = undefined;
  export let type: 'Order' | 'Lead' = 'Lead';

  const statusTransitions = {
    DRAFT: { next: 'PROCESSING', label: 'Send for processing' },
    PROCESSING: { next: 'ARCHIVE', label: 'Send to archive', prev: 'DRAFT' },
    ARCHIVE: {},
  };

  async function changeStatus(direction: 'next' | 'prev') {
    const currentStatus = $formData.status;
    const newStatus = statusTransitions[currentStatus]?.[direction];

    if (newStatus) {
      $formData.status = newStatus;

      if (newStatus === 'ARCHIVE') {
        await addDealToInvoice();
      }
    }

    await form.submit();
    if (newStatus === 'ARCHIVE') {
      let invoice = await getItemById('invoices', $formData.invoiceId);
      if (invoice) {
        const savedInvoice = await saveInvoice(invoice);
        console.log('savedInvoice', savedInvoice);
        invoice = await getItemById('invoices', $formData.invoiceId);
        if ($formData.shipment.paymentMethod !== 'Перечислением') {
          await finalizeInvoice($formData.invoiceId);
          await updateInvoiceStatus($formData.invoiceId, 'Paid');
        }
      }
    }
  }

  async function addDealToInvoice() {
    if ($formData.shipment.paymentMethod === 'Оплата другой стороной') {
      return;
    }

    const invoices = await findOpenInvoices($formData.customer);
    if (
      $formData.shipment.direction === 'IMPORT' ||
      $formData.shipment.paymentMethod !== 'Перечислением' ||
      invoices.length === 0
    ) {
      const newInvoice = await createItem('invoices', {
        name: 'New Invoice',
        status: 'Draft',
        customer: $formData.customer,
        positions: [],
        tags: [$formData.shipment.direction],
        invoiceDate: $formData.orderDate,
        currency: 'MDL',
      });

      $formData.invoiceId = newInvoice[0].id;
    } else {
      $formData.invoiceId = invoices[0].id;
    }
  }

  let awbNumber = '';
  $: statusActions = statusTransitions[$formData.status] || {};
</script>

<div class="p-6 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Icon icon="mdi:package" class="mr-2 size-6" />
    {type} Info
  </h2>
  <div class="space-y-4">
    <span class="text-muted-foreground">{$formData.id}</span>

    <div class="flex items-center">
      <strong class="w-32 text-gray-600">{type} No:</strong>
      <span class="mr-2 text-gray-800">{$formData.refId}</span>
    </div>

    <div class="flex items-center">
      <strong class="w-32 text-gray-600">Status:</strong>
      <Badge variant="outline" class="mr-2 rounded-none">
        <div>{$formData.status}</div>
      </Badge>
      {#if type === 'Lead'}
        {#if $formData.status === 'NEW'}
          <Dialog.Root>
            <Dialog.Trigger class={buttonVariants({ variant: 'default' })}
              >Convert to Order</Dialog.Trigger
            >
            <Dialog.Content class="sm:max-w-[425px]">
              <Dialog.Header>
                <Dialog.Title>Convert to Order</Dialog.Title>
              </Dialog.Header>
              <div class="grid gap-4 py-4">
                <div class="grid grid-cols-4 gap-4 items-center">
                  <Label for="name" class="text-right">AWB Number</Label>
                  <Input id="name" bind:value={awbNumber} class="col-span-3" />
                </div>
              </div>
              <Dialog.Footer>
                <Button on:click={() => createOrder && createOrder(awbNumber)}
                  >Convert</Button
                >
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        {/if}
      {:else}
        <div class="flex items-center">
          {#if statusActions.prev}
            <Button
              class="ml-2"
              variant="secondary"
              on:click={async () => await changeStatus('prev')}
            >
              Return
            </Button>
          {/if}
          {#if statusActions.next}
            <Button
              class="ml-2"
              variant="default"
              on:click={async () => await changeStatus('next')}
            >
              {statusActions.label}
            </Button>
          {/if}
        </div>
      {/if}
    </div>
    {#if type === 'Lead' && $formData.expectedCloseDate}
      <div class="flex items-center">
        <strong class="w-32 text-gray-600">Date:</strong>
        <span class="px-2 py-1 bg-blue-100 rounded text-primary">
          {formatDate($formData.expectedCloseDate)}
        </span>
      </div>
    {/if}

    {#if type === 'Order'}
      <HantaInputDate {form} name="orderDate" label="Order Date" {readOnly} />
      <HantaInputText {form} name="name" label="AWB Number" {readOnly} />

      <div class="flex items-center">
        {#if $formData.invoiceId}
          <Button
            variant="outline"
            on:click={() => {
              appStore.setCurrentModule('invoices');
              appStore.select({
                id: $formData.invoiceId,
                module: 'invoices',
              });
              /*
              contextStore.openPopup('crmObject', {
                module: 'invoices',
                id: $formData.invoiceId,
              });
              */
            }}
          >
            INVOICE
          </Button>
          <span class="ml-2 text-xs text-muted-foreground">
            {$formData.invoiceId}
          </span>
        {/if}
      </div>
    {/if}
    <UsersSelector
      bind:value={$formData.user}
      name="assignedTo"
      label="Registered by"
      multiple={false}
      {readOnly}
    />
    <HantaInputTextarea {form} name="notes" label="Notes" {readOnly} />
  </div>
</div>
