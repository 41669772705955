<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { fly } from 'svelte/transition';
  import { appStore } from '$lib/stores/app-store';
  import ItemsList from './list/items-list.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { cn } from '$lib/utils';
  import { getPrimaryRoutes } from '$lib/config';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';

  let countInPrimaryTab = 0;
  let countInSeondaryItemsTab = 0;
  const specialPages = [
    'accounts',
    'contacts',
    'deals',
    'invoices',
    'orders',
    'leads',
  ];
  $: isSpecialPage = specialPages.includes($appStore.currentModule);
</script>

{#if isSpecialPage}
  <div
    in:fly={{ x: -200, duration: 250 }}
    class={cn(
      'fixed overflow-auto  ',
      $appStore.isMobile
        ? 'h-full w-full'
        : $appStore.isCollapsed
          ? 'top-[0px] left-[70px] w-[240px] h-[100svh]'
          : 'top-[0px] left-[210px] w-[240px] h-[100svh]',
    )}
  >
    <Tabs.Root value="all" class="flex flex-col">
      <div class="flex items-center p-2 space-x-2">
        <h1 class="ml-1 text-xl font-bold capitalize md:flex-1">
          {$appStore.currentModule}
        </h1>
        <Tabs.List class="">
          <Tabs.Trigger value="all" class="text-zinc-600 dark:text-zinc-200">
            <span>All</span>
            <Badge variant="secondary" class="ml-2" data-testid="result-count"
              >{countInPrimaryTab}</Badge
            >
          </Tabs.Trigger>
          <Tabs.Trigger
            value="unread"
            class="text-zinc-600 dark:text-zinc-200 md:hidden"
          >
            <span>New</span>
            <Badge variant="secondary" class="ml-2"
              >{countInSeondaryItemsTab}</Badge
            >
          </Tabs.Trigger>
        </Tabs.List>
      </div>
      <Tabs.Content value="all" class="-mt-2">
        <ItemsList
          bind:count={countInPrimaryTab}
          module={$appStore.currentModule}
          current={$appStore.selected}
          selected={[]}
          sort={$appStore.sort}
          filters={$appStore.filters}
          on:goto={e => appStore.goToPage(e.detail)}
          on:next={appStore.nextPage}
          on:previous={appStore.prevPage}
          on:search={e => appStore.setSearch(e.detail)}
          on:sort={e => appStore.setSort(e.detail)}
          on:filters={e => appStore.setFilters(e.detail)}
          on:current={e => {
            appStore.select(e.detail);
            appStore.openPopup(true);
          }}
        />
      </Tabs.Content>
      <Tabs.Content value="unread" class="-mt-2">TODO</Tabs.Content>
    </Tabs.Root>
  </div>
{/if}
