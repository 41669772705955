<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import Icon from '@iconify/svelte';
  import { type ActivityWithRefs } from '$db/schema';
  import { type SuperForm } from 'sveltekit-superforms';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { getPrimaryRoutes } from '$lib/config';
  import { cn } from '$lib/utils';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { getItems } from '$lib/api/queries';

  export let form: SuperForm<ActivityWithRefs>;
  export let onSelectChanged: (
    value: { id: string; name: string; role: string }[],
  ) => void = () => {};
  export let selected: string;
  export let setSelected: (value: string) => void;

  const modules = ['contacts', 'deals', 'accounts', 'candidates', 'issues'];

  const createLoadOptions: (keyword: string) => any = (module: string) => {
    const additionalFields =
      module === 'deals'
        ? ',dealPhase,customer'
        : module === 'candidates' || module === 'contacts'
          ? ',role,photo'
          : module === 'accounts'
            ? ',logo'
            : '';

    let select = 'id,name' + additionalFields;

    return async (keyword: string): Promise<any> => {
      const result = await getItems({
        collection: module === 'candidates' ? 'contacts' : module,
        from: 0,
        to: 10,
        search: keyword,
        filters:
          module === 'candidates' || module === 'contacts'
            ? [
                {
                  field: 'role',
                  operator: 'in',
                  value: (module === 'candidates'
                    ? ['Candidate']
                    : ['Contact']
                  ).concat('ContactAndCandidate'),
                  active: true,
                },
              ]
            : [],
        select: select,
      });

      return result.data;
    };
  };

  const { form: formData } = form;

  $: count = modules.reduce((acc, key) => {
    return acc + $formData[key]?.length || 0;
  }, 0);
</script>

<div class="flex flex-row gap-4">
  <div
    class="flex flex-col content-start items-start py-1 md:w-48 bg-muted shrink-0"
  >
    <Button
      class="flex flex-row w-full capitalize border-b border-solid border-b-primary-200/30"
      on:click={() => setSelected('all')}
      variant="ghost"
    >
      <div
        class="w-full flex items-center gap-2"
        class:font-bold={'all' === selected}
      >
        <Icon icon="mdi:target" class="size-4" />
        <span class="hidden md:block">Overview</span>
      </div>
      <Badge class="ml-2" variant={'all' === selected ? 'default' : 'secondary'}
        >{count}</Badge
      >
    </Button>
    {#each modules as assignment (assignment)}
      <Button
        variant="ghost"
        class="flex flex-row w-full capitalize"
        on:click={() => setSelected(assignment)}
      >
        <div class="w-full" class:font-bold={assignment === selected}>
          <span class="hidden md:block">
            {assignment}
          </span>
          <Icon
            icon={getPrimaryRoutes().find(
              el =>
                el.module ===
                (assignment === 'candidates' ? 'contacts' : assignment),
            )?.icon}
            class={cn(
              'block size-4 md:hidden',
              assignment === 'candidates' ? 'text-destructive/50' : '',
            )}
            aria-hidden="true"
          />
        </div>
        <Badge
          variant={assignment === selected ? 'default' : 'secondary'}
          class="ml-2">{$formData[assignment]?.length ?? 0}</Badge
        >
      </Button>
    {/each}
  </div>

  <div class="flex flex-col w-2/3 md:w-full md:gap-1 md:p-4">
    {#key selected}
      {#if selected === 'all'}
        <div class="mt-3">
          {#each modules as assignment (assignment)}
            {#if $formData[assignment]?.length > 0}
              <div class="flex flex-col gap-1">
                <div class="text-sm font-bold capitalize">
                  {assignment}
                </div>
                <HantaInputMultiSelect
                  class="w-full"
                  {form}
                  loadOptions={createLoadOptions(assignment)}
                  name={assignment}
                  placeholder="Select {assignment}"
                  {onSelectChanged}
                >
                  <div
                    class="flex items-center h-full"
                    let:index
                    let:item
                    slot="item"
                  >
                    {#if assignment === 'issues'}
                      {item.name}
                    {:else}
                      <CrmAvatar
                        module={assignment === 'deals'
                          ? 'accounts'
                          : assignment}
                        id={assignment === 'deals' ? item?.customer : item?.id}
                        name={item?.name}
                      />
                    {/if}
                  </div>
                  <div
                    class="flex items-center h-full"
                    let:selection
                    slot="selection"
                  >
                    {#if assignment === 'issues'}
                      {selection.name}
                    {:else}
                      <CrmAvatar
                        module={assignment === 'deals'
                          ? 'accounts'
                          : assignment}
                        id={assignment === 'deals'
                          ? selection?.customer
                          : selection?.id}
                        name={selection?.name}
                      />
                    {/if}
                  </div>
                </HantaInputMultiSelect>
              </div>
            {/if}
          {/each}
        </div>
      {:else}
        <div class="text-sm font-bold capitalize">
          {selected}
        </div>

        <HantaInputMultiSelect
          class="w-full"
          {form}
          loadOptions={createLoadOptions(selected)}
          name={selected}
          placeholder="Select {selected}"
          {onSelectChanged}
        >
          <div class="flex items-center h-full" let:index let:item slot="item">
            {#if selected === 'issues'}
              {item.name}
            {:else}
              <CrmAvatar
                module={selected === 'deals' ? 'accounts' : selected}
                id={selected === 'deals' ? item?.customer : item?.id}
                name={item?.name}
              />
            {/if}
          </div>
          <div class="flex items-center h-full" let:selection slot="selection">
            {#if selected === 'issues'}
              {selection.name}
            {:else}
              <CrmAvatar
                module={selected === 'deals' ? 'accounts' : selected}
                id={selected === 'deals' ? selection?.customer : selection?.id}
                name={selection?.name}
              />
            {/if}
          </div>
        </HantaInputMultiSelect>
      {/if}
    {/key}
  </div>
</div>
