<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { getAccountOrders } from '$lib/api/queries';
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import { Button } from '$lib/components/ui/button';
  import { format } from 'date-fns';
  import { formatCurrency } from '$lib/utils';

  import { popupStore } from '$lib/stores/app-store';

  export let accountId: string;

  const ordersQuery = createQuery({
    queryKey: ['accountOrders', accountId],
    queryFn: () => getAccountOrders(accountId),
    enabled: !!accountId,
  });

  $: ({ data: orders, isLoading, isError, error } = $ordersQuery);

  function getStatusColor(status: string) {
    switch (status.toUpperCase()) {
      case 'NEW':
        return 'bg-blue-100 text-blue-800';
      case 'QUALIFIED':
        return 'bg-green-100 text-green-800';
      case 'PROPOSAL':
        return 'bg-yellow-100 text-yellow-800';
      case 'NEGOTIATION':
        return 'bg-purple-100 text-purple-800';
      case 'CLOSED WON':
        return 'bg-green-500 text-white';
      case 'CLOSED LOST':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }
</script>

{#if isLoading}
  <p>Loading orders...</p>
{:else if isError}
  <p>Error loading orders: {error.message}</p>
{:else if orders && orders.length > 0}
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Name</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Product</TableHead>
        <TableHead>Country</TableHead>
        <TableHead>Weight</TableHead>
        <TableHead>Total</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {#each orders as order}
        <TableRow>
          <TableCell>{order.name}</TableCell>
          <TableCell>
            <span
              class={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}
            >
              {order.status}
            </span>
          </TableCell>

          <TableCell>{order.shipment?.product}</TableCell>
          <TableCell>{order.shipment?.country}</TableCell>
          <TableCell>{order.shipment?.weight} kg</TableCell>
          <TableCell
            >
            {order.shipment?.rate?.totalEUR
              ? formatCurrency(order.shipment?.rate?.totalEUR)
              : '-'}</TableCell
          >
          <TableCell>
            <Button
              variant="outline"
              size="sm"
              on:click={() => {
                popupStore.openPopup('crmObject', {
                  id: order.id,
                  module: 'orders',
                });
              }}>View</Button
            >
          </TableCell>
        </TableRow>
      {/each}
    </TableBody>
  </Table>
{:else}
  <p>No leads found for this account.</p>
{/if}
