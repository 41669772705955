<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import InvoiceChangeStatus from './invoice-change-status.svelte';
  import Icon from '@iconify/svelte';

  import { currencyFormatter } from '$lib/app/utils';
  import InvoiceStatus from '$lib/components/hanta/invoice-status.svelte';

  import { appStore } from '$lib/stores/app-store';
  import { saveInvoice } from '$lib/api/invoice-api';
  import { authStore } from '$lib/stores/auth-store';

  export let form;
  export let total;
  export let sum;
  export let isLocked;
  export let hasChanges;
  export let onFinalize;

  const { form: formData } = form;

  const format = currencyFormatter().format;

  const duplicate = async () => {
    const invoice = {
      ...$formData,
    };
    invoice.id = undefined;
    invoice.invoiceId = undefined;
    invoice.status = 'Draft';
    invoice.invoiceDate = new Date().toISOString();

    const data = await saveInvoice(invoice);
    appStore.select({ id: data.id, module: 'invoices' });
    appStore.openPopup(true);
  };
</script>

<div class="flex justify-between items-center w-full">
  <div class="flex items-center">
    <span class="mr-1 font-normal">Invoice #</span>
    {$formData.invoiceId}
    <InvoiceStatus status={$formData.status} />
  </div>

  <div class="flex items-center space-x-4">
    <div class="flex flex-col text-right">
      <span class="text-lg font-bold">{format(sum.sum)}</span>
      <div class="flex text-xs text-muted-foreground">
        Net {format(sum.sum - sum.tax)} / VAT {format(sum.tax)}
      </div>
    </div>

    {#if !isLocked && !hasChanges && $formData.status === 'Draft'}
      <AlertDialog.Root>
        <AlertDialog.Trigger asChild let:builder>
          <Button builders={[builder]}>
            <Icon icon="mdi:lock" class="mr-2 size-4" />
            Finalize invoice
          </Button>
        </AlertDialog.Trigger>
        <AlertDialog.Content>
          <AlertDialog.Header>
            <AlertDialog.Title>Finalize this invoice?</AlertDialog.Title>
            <AlertDialog.Description>
              This will finalize the invoice, ensuring no modifications are
              possible afterward except for cancellation, thus maintaining the
              integrity of the invoicing process.
            </AlertDialog.Description>
          </AlertDialog.Header>
          <AlertDialog.Footer>
            <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
            <AlertDialog.Action on:click={onFinalize}
              >Finalize now</AlertDialog.Action
            >
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog.Root>
    {/if}

    {#if $formData.status === 'Open' || $formData.status === 'Overdue'}
      <InvoiceChangeStatus id={$formData.id} sum={sum.sum} />
    {/if}

    {#if !authStore.isLogistics()}
      <Button variant="ghost" on:click={duplicate}>
        <Icon icon="mdi:content-copy" class="mr-2 size-4" />
        <span> Duplicate</span>
      </Button>
    {/if}
  </div>
</div>
