<script lang="ts">
  import { dndzone, type DndEvent } from 'svelte-dnd-action';
  import PipelinePhaseCard from './pipeline-phase-card.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import type { Pipeline } from '$lib/types/pipeline';
  import { addPipelineItem, removePipelineItem } from '$lib/api/pipeline-api';
  import { writable } from 'svelte/store';
  import { fade, fly } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';
  import BuilderActions from './builder-actions.svelte';

  export let pipeline: Pipeline;
  export let isPreviewMode: boolean;
  export let onPhaseRemove: (id: string) => void;
  export let onPhaseReorder: (phases: Phase[]) => void;
  export let onPhaseAdd: () => void;
  export let onSavePipeline: () => void;

  const isLoading = writable(false);
  const error = writable<string | null>(null);

  function handleDndConsider(e: CustomEvent<DndEvent<Phase>>) {
    if (!e.detail.items) return;
    onPhaseReorder(e.detail.items);
  }

  function handleDndFinalize(e: CustomEvent<DndEvent<Phase>>) {
    if (!e.detail.items) return;
    onPhaseReorder(e.detail.items);
  }

  $: phases = pipeline?.phases || [];

  async function onAddItem(phaseId: string, item: any) {
    try {
      isLoading.set(true);
      error.set(null);
      
      if (pipeline.modifySource) {
        const phase = pipeline.phases.find(p => p.id === phaseId);
        if (!phase) throw new Error('Phase not found');
        phase.items.push(item);
      } else {
        await addPipelineItem(phaseId, pipeline.module, item);
      }
    } catch (e) {
      error.set(e instanceof Error ? e.message : 'Failed to add item');
      console.error('Error adding item:', e);
    } finally {
      isLoading.set(false);
    }
  }

  async function onRemoveItem(phaseId: string, itemId: string) {
    try {
      isLoading.set(true);
      error.set(null);

      if (pipeline.modifySource) {
        const phase = pipeline.phases.find(p => p.id === phaseId);
        if (!phase) throw new Error('Phase not found');
        phase.items = phase.items.filter(item => item.id !== itemId);
      } else {
        await removePipelineItem(phaseId, itemId);
      }
    } catch (e) {
      error.set(e instanceof Error ? e.message : 'Failed to remove item');
      console.error('Error removing item:', e);
    } finally {
      isLoading.set(false);
    }
  }


</script>


{#if $error}
  <div 
    role="alert" 
    class="error-message"
    in:fly={{ y: -20, duration: 300, easing: quintOut }}
    out:fade={{ duration: 200 }}
  >
    <div class="flex items-center gap-2">
      <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      {$error}
    </div>
  </div>
{/if}

<div class="mb-2">
  <BuilderActions {onPhaseAdd} onSave={onSavePipeline} />
</div>


<div
  use:dndzone={{ items: phases, flipDurationMs: 300 }}
  on:consider={handleDndConsider}
  on:finalize={handleDndFinalize}
  class="phases-container"
  role="list"
  aria-label="Pipeline phases"
>
  {#each phases as phase (phase.id)}
    <div 
      role="listitem" 
      class="phase-item"
      in:fly|local={{ x: 20, duration: 300, easing: quintOut }}
    >
      <PipelinePhaseCard
        {phase}
        {isPreviewMode}
        module={pipeline.module}
        onRemove={onPhaseRemove}
        onAddItem={item => onAddItem(phase.id, item)}
        onRemoveItem={(phaseId, itemId) => onRemoveItem(phaseId, itemId)}
      />
    </div>
  {/each}
</div>

{#if phases.length === 0}
  <div 
    class="empty-state" 
    role="status"
    in:fade={{ duration: 200 }}
  >
    <svg class="w-12 h-12 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" 
        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
      />
    </svg>
    <p class="text-gray-600 text-center">No phases added yet.</p>
    <p class="text-sm text-gray-500 mt-1">Click "Add Phase" to begin building your pipeline.</p>
  </div>
{/if}

{#if $isLoading}
  <div 
    class="loading-indicator"
    in:fly={{ y: 20, duration: 300, easing: quintOut }}
    out:fade={{ duration: 200 }}
  >
    <span>Processing</span>
    <div class="flex gap-1">
      <div class="loading-dot" style="animation-delay: 0ms" />
      <div class="loading-dot" style="animation-delay: 100ms" />
      <div class="loading-dot" style="animation-delay: 200ms" />
    </div>
  </div>
{/if}



<style lang="postcss">
  .phases-container {
    @apply relative flex overflow-x-auto pb-6 gap-4 px-4 md:px-6;
    scroll-padding: 1rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    @apply p-4
  }

  .phases-container::-webkit-scrollbar {
    @apply h-2;
  }

  .phases-container::-webkit-scrollbar-track {
    @apply bg-gray-100 rounded-full;
  }

  .phases-container::-webkit-scrollbar-thumb {
    @apply bg-gray-300 rounded-full hover:bg-gray-400 transition-colors;
  }

  .phase-item {
    @apply flex-shrink-0 transition-transform duration-300 ease-out;
  }

  .phase-item:hover {
    @apply transform scale-[1.02];
  }

  .empty-state {
    @apply flex flex-col items-center justify-center py-12 px-4 rounded-lg 
           bg-gray-50 border-2 border-dashed border-gray-200 
           transition-all duration-300 ease-out;
  }

  .empty-state:hover {
    @apply border-primary/30 bg-gray-50/80;
  }

  .error-message {
    @apply fixed top-4 right-4 bg-red-50 text-red-700 px-6 py-3 rounded-lg shadow-lg
           border border-red-200 backdrop-blur-sm;
  }

  .loading-indicator {
    @apply fixed bottom-4 right-4 bg-primary/90 text-white px-6 py-3 
           rounded-lg shadow-lg backdrop-blur-sm flex items-center gap-2;
  }

  .loading-dot {
    @apply w-2 h-2 bg-white rounded-full animate-pulse;
  }
</style>
