<script>
  import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query';
  import { toast } from 'svelte-sonner';
  import { supabase } from '$lib/api/supabase';
  import { ModeWatcher } from 'mode-watcher';
  import { Plane } from 'svelte-loading-spinners';
  import { appStore } from '$lib/stores/app-store';
  import { Toaster } from '$lib/components/ui/sonner';
  import MainWidget from '$lib/widgets/main-widget.svelte';
  import ActivityPopup from '$lib/pages/activities/activity-popup.svelte';
  import CrmApp from '$lib/app/crm-app.svelte';
  import CrmChecker from '$lib/checker/crm-checker.svelte';
  import LinkedInChecker from '$lib/checker/linkedIn-checker.svelte';
  import { contactLocal } from '$lib/mock-data';
  import { onDestroy, onMount } from 'svelte';
  import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
  import { authStore } from '$lib/stores/auth-store';
  import { get } from 'svelte/store';

  let contact = import.meta.env.DEV ? contactLocal : null;
  let subscription;
  let reconnectTimeout;
  let reconnectAttempts = 0;
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_DELAY = 5000;
  const exceptionTables = ['search', 'rates', 'shipments'];

  function unsubscribe() {
    try {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
      // Call removeAllChannels after a small delay to ensure proper cleanup
      setTimeout(() => {
        supabase($authStore.token).removeAllChannels();
        appStore.setConnectionStatus('disconnected');
      }, 100);
    } catch (error) {
      console.error('Error during unsubscribe:', error);
    }
  }

  function getBackoffDelay() {
    // Exponential backoff with max delay of 30 seconds
    return Math.min(RECONNECT_DELAY * Math.pow(2, reconnectAttempts), 30000);
  }

  function attemptReconnect() {
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }

    if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      toast.error('Connection lost. Please refresh the page.');
      appStore.setConnectionStatus('error');
      return;
    }

    const token = get(authStore).token;
    if (!token) {
      console.error('No auth token available for reconnection');
      return;
    }

    reconnectAttempts++;
    const delay = getBackoffDelay();

    console.log(
      `Attempting to reconnect (${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}) in ${delay / 1000}s...`,
    );

    reconnectTimeout = setTimeout(() => {
      unsubscribe();
      // Add a small delay before resubscribing to ensure proper cleanup
      setTimeout(() => {
        subcribeToChannels();
      }, 100);
    }, delay);
  }

  function subcribeToChannels() {
    const token = get(authStore).token;
    if (!token) {
      console.error('No auth token available for subscription');
      return;
    }

    console.log('Subscribing to channels...');

    try {
      subscription = supabase(token)
        .channel('*')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public' },
          async payload => {
            // Reset reconnect attempts on successful connection
            reconnectAttempts = 0;
            console.log('realtime payload: ', payload);

            if (exceptionTables.includes(payload.table)) {
              console.debug('Exception table: ', payload.table);
              return;
            }
            console.debug('Database changes detected: ', payload);

            if (payload.table === 'dealsToContacts') {
              const { contactId, dealId } = payload.new;

              $appStore.queryClient.invalidateQueries(['deals', dealId], {
                exact: true,
                refetchActive: true,
              });
              $appStore.queryClient.invalidateQueries(['contacts', contactId], {
                exact: true,
                refetchActive: true,
              });
            } else {
              if (payload.table !== 'sequences') {
                toast.success(
                  'Changes in ' +
                    payload.table.charAt(0).toUpperCase() +
                    payload.table.slice(1),
                );
              }
            }

            const { eventType, old } = payload;

            if (eventType === 'DELETE' && old?.id) {
              if ($appStore.selected?.id === old.id) {
                appStore.openPopup(false);
                appStore.select(undefined);
              }
              if ($appStore.crmAccount?.id === old.id) {
                appStore.setCrmAccount(undefined);
              }
              if ($appStore.crmContact?.id === old.id) {
                appStore.setCrmContact(undefined);
              }
            }

            if (payload.new?.id) {
              if ($appStore.crmAccount?.id === payload.new.id) {
                appStore.setCrmAccount(payload.new);
              }
              if ($appStore.crmContact?.id === payload.new.id) {
                appStore.setCrmContact(payload.new);
              }
            }

            if ($appStore.queryClient) {
              $appStore.queryClient.invalidateQueries([payload.table], {
                exact: false,
                refetchActive: true,
              });

              if (payload.table === 'activities') {
                $appStore.queryClient.invalidateQueries(
                  ['activities', 'todos'],
                  {
                    exact: false,
                    refetchActive: true,
                  },
                );
                if (payload.new.type === 'Assignment') {
                  $appStore.queryClient.invalidateQueries(
                    ['assignments', payload.new.deal.id],
                    {
                      exact: false,
                      refetchActive: true,
                    },
                  );
                }
              }
            }
          },
        )
        .on('presence', { event: 'sync' }, () => {
          reconnectAttempts = 0;
          appStore.setConnectionStatus('connected');
        })
        .on('presence', { event: 'join' }, () => {
          reconnectAttempts = 0;
          appStore.setConnectionStatus('connected');
        })
        .on('presence', { event: 'leave' }, () => {
          appStore.setConnectionStatus('disconnected');
          attemptReconnect();
        })
        .on('system', { event: '*' }, payload => {
          if (payload.event === 'disconnected') {
            appStore.setConnectionStatus('disconnected');
            toast.error('Lost connection to server');
            attemptReconnect();
          }
        })
        .subscribe(status => {
          if (status === 'SUBSCRIBED') {
            reconnectAttempts = 0;
            appStore.setConnectionStatus('connected');
          } else if (status === 'CLOSED') {
            appStore.setConnectionStatus('disconnected');
            // toast.error('Connection closed');
            attemptReconnect();
          } else if (status === 'CHANNEL_ERROR') {
            appStore.setConnectionStatus('error');
            // toast.error('Connection error');
            attemptReconnect();
          }
        });
    } catch (error) {
      console.error('Error during subscription:', error);
      attemptReconnect();
    }
  }

  onMount(async () => {
    if (!$appStore.queryClient) {
      const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60 * 1000,
          },
        },
      });
      appStore.setQueryClient(queryClient);

      // Add small delay before initial subscription
      setTimeout(() => {
        subcribeToChannels();
      }, 100);
    }
  });

  onDestroy(() => {
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }
    unsubscribe();
  });
</script>

{#if $appStore.queryClient}
  <QueryClientProvider client={$appStore.queryClient}>
    {#if false && import.meta.env.DEV}
      <SvelteQueryDevtools initialIsOpen={false} />
    {/if}
    <div class="app bg-primary-foreground">
      <ModeWatcher track={true} />
      <Toaster class="z-[100000]" position="bottom-left" />

      <CrmApp />

      {#if $appStore.isParsing}
        <div class="ml-4 min-h-[60px]">
          <Plane size="15" color="#09D2C2" unit="px" duration="1.5s" />
        </div>
      {/if}

      <MainWidget />

      <LinkedInChecker />
      <CrmChecker linkedin={contact?.linkedin} />
      <ActivityPopup />
    </div>
  </QueryClientProvider>
{/if}

<style>
  :global(body) {
    margin: 0;
    font-family: system-ui, sans-serif;
  }

  .app {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    z-index: 10000;
  }

  div :global(svg) {
    font-size: 16px;
    line-height: 1em;
  }

  div :global(.big-icon) {
    font-size: 72px;
  }
</style>
