<script lang="ts">
  import TemplateUpload from './template-upload.svelte';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import Icon from '@iconify/svelte';
  import WordIcon from './../../components/icons/word-icon.svelte';

  import FilePreview from '$lib/components/hanta/files/file-preview.svelte';
  import { Button } from '$lib/components/ui/button';
  import {
    getFileUrl,
    uploadBlob,
    downloadFile,
    listFolders,
  } from '$lib/api/storage';
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils';
  import DropdownMenuSeparator from '$lib/components/ui/dropdown-menu/dropdown-menu-separator.svelte';
  import { generateDocx } from '$lib/api/docx-api';
  import { authStore } from '$lib/stores/auth-store';
  import { toast } from 'svelte-sonner';
  import { generateQR } from '$lib/pages/invoices/invoice-utils';

  type $$Props = {
    class?: string;
    templateName?: string;
    data: any;
    qrCodeData?: any;
    label?: string;
    customFilename?: string;
  };

  const templateUrl = '/templates/';
  export let templateName = 'template.docx';
  export let data;
  export let qrCodeData = undefined;
  export let label = 'Profile';
  export let customFilename;
  export { className as class };

  let className: $$Props['class'] = undefined;
  let templateFile;
  let isLoaded = false;
  let showPreview = false;
  let previewUrl = '';

  $: filename = customFilename
    ? `${(customFilename ?? '').replaceAll(' ', '_')}`
    : `${data?.name ?? ''}_${new Date().toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}`.replaceAll(
        ' ',
        '_',
      );

  const REVISIONS_PATH = '__revisions';
  $: revisionsPath = templateUrl + templateName + REVISIONS_PATH;
  let revisions = [];

  $: templateUrl &&
    listFolders(revisionsPath.substring(1)).then(res => {
      revisions = res?.map(el => el.name);
    });

  async function downloadTemplate(revision?: string) {
    let template = templateFile;
    if (revision) {
      template = await downloadFile(
        revisionsPath + '/' + revision,
        templateName,
      );
    }
    downloadBlob(template, 'template.docx', true);
  }

  let showTemplateUploadDialog = false;
  let showTemplateUpload = () => {
    showTemplateUploadDialog = true;
  };

  onMount(async () => {
    try {
      const template = await downloadFile(templateUrl, templateName);
      templateFile = template;
    } catch (e) {
      if (e.message === 'Object not found') {
        // ignore
      } else {
        console.error(e);
      }
    } finally {
      isLoaded = true;
    }
  });

  $: revisionTemplateUrl = templateName + REVISIONS_PATH;

  async function generateDocument(download: boolean, revision?: string) {
    const qrCode = qrCodeData ? await generateQR(qrCodeData) : undefined;
    const renderedDoc = {
      ...data,
      qrCode: { image: qrCode, width: 1.5, height: 1.5 },
      meta: {
        orgId: $authStore?.organization?.id,
        templateName,
        templateUrl:
          templateUrl +
          (revision ? revisionTemplateUrl + '/' + revision + '/' : ''),
        filename,
      },
    };

    try {
      const { signedUrl } = await generateDocx(renderedDoc);
      if (download) {
        console.debug('Downloading', filename);
        saveAs(signedUrl, filename);
      } else {
        previewUrl = signedUrl;
        showPreview = true;
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate document');
      return;
    }
  }

  function saveAs(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function downloadBlob(blob, filename, downloadFile: boolean) {
    const url = window.URL.createObjectURL(blob);

    if (!downloadFile) {
      const fullFilename = '/temp/' + self.crypto.randomUUID() + '_' + filename;
      await uploadBlob(blob, fullFilename);
      const { signedUrl } = await getFileUrl('', fullFilename, 60 * 60);
      previewUrl = signedUrl;
      showPreview = true;

      return;
    } else {
      saveAs(url, filename);
    }
  }
</script>

{#if isLoaded}
  {#if showTemplateUploadDialog}
    <TemplateUpload
      bind:open={showTemplateUploadDialog}
      bind:templateFile
      {templateName}
      {templateUrl}
    />
  {/if}

  {#if !templateFile}
    <div class="text-xs">No templates so far</div>
    <Button on:click={() => (showTemplateUploadDialog = true)}
      >Upload a template</Button
    >
  {:else}
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="secondary"
          class={cn(' flex justify-between bg-secondary w-full ', className)}
        >
          <div><WordIcon /></div>
          <div class="ml-2 text">{label}</div>

          <Icon
            icon="mdi:chevron-up"
            class={cn(
              'size-4',
              !builder['aria-expanded'] && 'translate rotate-180',
            )}
          />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content class="w-36">
        <DropdownMenu.Item on:click={() => generateDocument(false)}>
          Preview
        </DropdownMenu.Item>
        <DropdownMenu.Item on:click={() => generateDocument(true)}>
          Download
        </DropdownMenu.Item>

        {#each revisions || [] as revision}
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger
              >Revision [{revision}]</DropdownMenu.SubTrigger
            >
            <DropdownMenu.SubContent>
              <DropdownMenu.Item
                on:click={() => generateDocument(false, revision)}
              >
                Preview
              </DropdownMenu.Item>
              <DropdownMenu.Item
                on:click={() => generateDocument(true, revision)}
              >
                Download
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        {/each}

        <DropdownMenuSeparator />
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>Template</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            <DropdownMenu.Item on:click={showTemplateUpload}
              >Upload</DropdownMenu.Item
            >
            <DropdownMenuSeparator />
            <DropdownMenu.Item on:click={() => downloadTemplate()}
              >Download</DropdownMenu.Item
            >

            {#each revisions || [] as revision}
              <DropdownMenu.Item on:click={() => downloadTemplate(revision)}>
                [{revision}] Download
              </DropdownMenu.Item>
            {/each}

            <!--
            <DropdownMenu.Separator />
            <DropdownMenu.Item>More...</DropdownMenu.Item>
            -->
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
        <!--DropdownMenuSeparator />
        <DropdownMenu.Item on:click={() => generateDocument(true)}>
          Download Template
        </DropdownMenu.Item>
        <DropdownMenu.Item on:click={() => generateDocument(true)}>
          Upload Template
        </DropdownMenu.Item-->
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  {/if}
  <FilePreview
    file={{
      name: filename,
      mimetype:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }}
    bind:open={showPreview}
    {previewUrl}
    officeDocument={true}
  />
{/if}
