<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import Select from 'svelte-select';
  import { cn } from '$lib/utils';
  import { tick } from 'svelte';
  import { Badge } from '$lib/components/ui/badge';
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';
  import { formFieldProxy } from 'sveltekit-superforms/client';

  export let form;
  export let name;
  export let label = undefined;
  export let description = '';
  export let placeholder = '';
  export let simpleMode = false;
  export let multiple = true;
  export let options = [];
  export let disabled = false;
  export let asArray = false;
  export let readOnly = false;
  export let addable = false;
  export let icon: any | undefined;
  export let variant: 'default' | 'light' = 'default';
  export let loadOptions: ((keyword: string) => any) | undefined = () => [];
  export let onItemAdded: ((item: any) => any) | undefined = () => [];

  const { value } = formFieldProxy(form, name);

  let items: any[];
  let svelteSelect: Select;

  type $$Props = {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    simpleMode?: boolean;
    disabled?: boolean;
    icon: any | undefined;
    multiple?: boolean;
    options?: any[];
    class?: string;
    asArray?: boolean;
    readOnly?: boolean;
    loadOptions?: ((keyword: string) => any) | undefined;
    onSelectChanged?: (v: any) => void;
    onItemAdded?: (item: any) => void;
    variant?: 'default' | 'light';
    addable?: boolean;
  };

  let className: $$Props['class'] = 'w-full';
  export { className as class };
  export let onSelectChanged: ((v: any) => void) | undefined = undefined;

  // Helper function to process values
  const processValue = item => {
    if (simpleMode) {
      if (typeof item === 'string' && item.startsWith('Add: ')) {
        const newValue = item.replace('Add: ', '');
        tick().then(() => onItemAdded && onItemAdded(newValue));
        return newValue;
      }
      return item?.value || item;
    }
    return item.value || item;
  };

  const selectionChanged = async e => {
    let newValue;

    if (multiple) {
      newValue = e.detail.map(processValue);
    } else {
      newValue = asArray ? [processValue(e.detail)] : processValue(e.detail);
    }

    $value = newValue;
    await tick();
    onSelectChanged?.(newValue);
  };

  const handleClear = async e => {
    const vals = e.detail;
    let newValue;

    if (multiple || asArray) {
      if (Array.isArray(vals)) {
        newValue = ($value || []).filter(
          a => !vals.some(v => v.id === a.id || v === a),
        );
      } else {
        const valueToRemove = vals.id || vals.value || vals;
        newValue = ($value || []).filter(
          a => a.id !== valueToRemove && a !== valueToRemove,
        );
      }
    } else {
      newValue = null;
    }

    $value = newValue;
    await tick();
    onSelectChanged?.(newValue);
  };

  function isSelected(item) {
    if (!$value) return true;
    const itemValue = item.id || item.value || item;

    if (multiple || asArray) {
      return !$value.some(v => v.id === itemValue || v === itemValue);
    }
    return $value !== itemValue;
  }

  $: loadOptionsInternal = async query => {
    if (options?.length > 0) {
      return options.map(item => ({
        ...item,
        value: item,
        selectable: isSelected(item),
      }));
    }

    if (!loadOptions) return [];

    const result = await loadOptions(query);
    return result?.map(item => ({
      ...item,
      value: item,
      selectable: isSelected(item),
    }));
  };

  $: selectedValue = multiple
    ? $value?.map(a => (simpleMode ? a : { value: a, ...a }))
    : asArray
      ? $value && $value[0]
        ? { value: $value[0], ...$value[0] }
        : undefined
      : $value
        ? { value: $value, ...$value }
        : undefined;

  let filterText = '';
  let filterChanged = false;

  const handleFilter = async e => {
    if (!addable || !(simpleMode && multiple)) return;

    if (filterChanged) {
      filterChanged = false;
      return;
    }

    const prevOptions = options.filter((v: string) => !v.startsWith('Add:'));
    if (
      e.detail.some(v => v.value?.toLowerCase() === filterText.toLowerCase())
    ) {
      options = prevOptions;
    } else {
      options =
        filterText.length > 0
          ? ['Add: ' + filterText, ...prevOptions]
          : prevOptions;
    }

    filterChanged = true;
  };

  const handleBlur = async () => {
    if (simpleMode && multiple) {
      filterText = '';
      options = options.filter((v: string) => !v.startsWith('Add: '));
    }
  };
</script>

<Form.Field
  class={cn('grid-cols-1 grid-rows-1 gap-y-2 grid', className)}
  {form}
  {name}
>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label class="flex flex-row gap-2 items-center">
        <slot name="icon" />
        {#if icon}
          <HantaIcon {icon} />
        {/if}
        {label}
      </Form.Label>
    {/if}
    {#if !readOnly}
      <Select
        bind:this={svelteSelect}
        bind:filterText
        on:change={selectionChanged}
        on:clear={handleClear}
        on:filter={handleFilter}
        on:blur={handleBlur}
        {disabled}
        {multiple}
        items={options?.length > 0 ? options : items}
        itemId="value"
        loadOptions={simpleMode ? undefined : loadOptionsInternal}
        placeholder={placeholder || label}
        value={selectedValue}
      >
        <svelte:fragment let:index let:item slot="item">
          {#if $$slots.item}
            <slot {index} {item} name="item" />
          {:else}
            <div class="flex items-center h-full">
              <div class="text-sm">{item?.label}</div>
            </div>
          {/if}
        </svelte:fragment>

        <svelte:fragment let:selection slot="selection">
          {#if $$slots.selection}
            <slot name="selection" {selection} />
          {:else}
            <div class="flex items-center h-full">
              <div class="ml-2 text-sm">
                {selection?.label || selection?.value}
              </div>
            </div>
          {/if}
        </svelte:fragment>
      </Select>
    {:else if $$slots.selection}
      <slot name="selection" selection={selectedValue} />
    {:else}
      <div class="flex items-center h-full">
        {#each selectedValue || [] as item}
          <Badge class="ml-2 text-xs truncate">{item}</Badge>
        {/each}
      </div>
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  {#if variant !== 'light'}
    <Form.FieldErrors />
  {/if}
</Form.Field>

<style lang="postcss">
  :global(.svelte-select .value-container) {
    flex-shrink: 0;
  }

  :global(.svelte-select-list) {
    @apply !bg-primary-foreground;
  }

  :global(.svelte-select) {
    --item-is-active-bg: theme(colors.primary.DEFAULT);
    --item-is-active-color: theme(colors.secondary.DEFAULT);
    border: solid 1px theme(colors.gray.200) !important;
    @apply !border-primary/20 hover:!bg-muted transition-all !px-2 shrink-0 border border-muted hover:border-primary/20;
    --multi-select-padding: 0;
    --multi-select-border: solid 1px theme(colors.gray.200);
    --border-hover: solid 1px theme(colors.gray.200);
    --border: solid 1px theme(colors.gray.200);
    --border-radius: 3px;
    --multi-item-border-radius: 3px;
    --multi-item-bg: theme(colors.muted.DEFAULT);
    --item-hover-bg: theme(colors.muted.DEFAULT);
    --multi-item-clear-icon-color: theme(colors.primary.DEFAULT);
  }

  :global(.svelte-select input::placeholder) {
    @apply px-2;
  }

  :global(.svelte-select input:focus, .svelte-select input:hover) {
    box-shadow: unset;
  }

  :global(.svelte-select input) {
    --tw-ring-shadow: 0 0 #000 !important;
    --placeholder-color: theme(colors.primary.DEFAULT);
    @apply !text-sm font-medium !text-primary;
    box-shadow: unset;
  }

  :global(.svelte-select:hover) {
    border: none;
  }
</style>
