import { get, writable } from 'svelte/store';
import { ActivityWithRefs } from '$db/schema';
import { isMobile } from '../utils/browser-utils';

export type SortBy = {
  column: string;
  order: 'asc' | 'desc';
};

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected' | 'error';

const defaultItemsPerPage = 10;

type AppStore = {
  queryClient?: any;
  openPopup: boolean;
  openActivity: boolean;
  isCollapsed: boolean;

  isMobile: boolean;

  isParsing: boolean;
  connectionStatus: ConnectionStatus;
  linkedInAccount?: any;
  linkedInContact?: any;
  crmContact?: any;
  crmAccount?: any;

  selected: any;
  selectedStack: any[];
  currentModule?: string;
  currentActivity?: ActivityWithRefs;
  filters?: any;
  sort?: SortBy;
  search?: string;
  from?: number;
  to?: number;
  itemsPerPage?: number;
};

type ContextStore = {
  isOpen: boolean;
  popupType: string;
  popupData?: any;
  // Add other context-related properties as needed
};

type PopupStore = {
  isOpen: boolean;
  popupType: string;
  popupData?: any;
  // Add other popup-related properties as needed
};

function createAppStore() {
  const store = writable<AppStore>({
    isCollapsed: true,
    isMobile: isMobile(),
    openPopup: false,
    openActivity: false,
    isParsing: false,
    connectionStatus: 'connecting',
    selected: undefined,
    currentModule: undefined,
    search: '',
    sort: {
      column: 'name',
      order: 'asc',
    },
    from: 0,
    itemsPerPage: defaultItemsPerPage,
    to: defaultItemsPerPage,
    selectedStack: [],
  });

  return {
    subscribe: store.subscribe,
    setSort: async (sort: SortBy) => {
      const { currentModule, sort: oldSort, itemsPerPage } = get(store);
      if (!currentModule) return;
      if (sort === oldSort) return;
      store.update(s => ({ ...s, sort, from: 0, to: itemsPerPage }));
    },
    setConnectionStatus: (status: ConnectionStatus) => {
      store.update(s => ({ ...s, connectionStatus: status }));
    },
    toggleCollapse: () => {
      store.update(s => ({ ...s, isCollapsed: !s.isCollapsed }));
    },
    setFilters: async (filters: any) => {
      const { currentModule, filters: oldFilters, itemsPerPage } = get(store);
      if (!currentModule) return;
      if (filters === oldFilters) return;
      store.update(s => ({ ...s, filters, from: 0, to: itemsPerPage }));
    },
    setSearch: async (search: string) => {
      const { currentModule, search: oldSearch, itemsPerPage } = get(store);
      if (!currentModule) return;
      if (search === oldSearch) return;
      store.update(s => ({ ...s, search, from: 0, to: itemsPerPage }));
    },
    setParsing: (isParsing: boolean) => {
      store.update(s => ({ ...s, isParsing }));
    },
    setLinkedInAccount: (linkedInAccount?: any) => {
      store.update(s => ({ ...s, linkedInAccount }));
    },
    setLinkedInContact: (linkedInContact?: any) => {
      store.update(s => ({ ...s, linkedInContact }));
    },
    setCrmContact: (crmContact?: any) => {
      store.update(s => ({ ...s, crmContact }));
    },
    setSelectedStack: (selectedStack: any[]) => {
      store.update(s => ({ ...s, selectedStack }));
    },
    setCrmAccount: (crmAccount?: any) => {
      store.update(s => ({ ...s, crmAccount }));
    },
    setQueryClient: (queryClient: any) => {
      store.update(s => ({ ...s, queryClient }));
    },
    setSelected: (selected: any) => {
      store.update(s => ({ ...s, selected }));
    },
    setCurrentModule: async (currentModule?: string) => {
      const { currentModule: oldCurrentModule, itemsPerPage } = get(store);
      if (currentModule === oldCurrentModule) return;

      store.update(s => ({
        ...s,
        currentModule,
        selected: undefined,
        filters: undefined,
        sort: undefined,
        search: '',
        from: 0,
        to: itemsPerPage,
      }));
    },
    openPopup: (openPopup: boolean) => {
      store.update(s => ({
        ...s,
        openPopup,
        selectedStack: openPopup ? s.selectedStack : [],
      }));
    },
    openActivity: (currentActivity: ActivityWithRefs) => {
      store.update(s => ({ ...s, currentActivity, openActivity: true }));
    },
    closeActivity: () => {
      store.update(s => ({
        ...s,
        openActivity: false,
        currentActivity: undefined,
      }));
    },
    select: (payload?: { id?: string; module: string; tab?: string }) => {
      store.update(s => ({
        ...s,
        selected: payload,
      }));
    },
    nextPage: async () => {
      const { from, to, itemsPerPage } = get(store);
      store.update(s => ({
        ...s,
        from: from + itemsPerPage,
        to: to + itemsPerPage,
      }));
    },
    prevPage: async () => {
      const { from, to, itemsPerPage } = get(store);
      store.update(s => ({
        ...s,
        from: from - itemsPerPage,
        to: to - itemsPerPage,
      }));
    },
    goToPage: async (page: number) => {
      const { itemsPerPage } = get(store);
      store.update(s => ({
        ...s,
        from: (page - 1) * itemsPerPage,
        to: page * itemsPerPage,
      }));
    },
  };
}

function createPopupStore() {
  const store = writable<PopupStore>({
    isOpen: false,
    popupType: '',
    popupData: undefined,
  });

  return {
    subscribe: store.subscribe,
    openPopup: (popupType: string, popupData?: any) => {
      store.update(s => ({ ...s, isOpen: true, popupType, popupData }));
    },
    closePopup: () => {
      store.update(s => ({
        ...s,
        isOpen: false,
        popupType: '',
        popupData: undefined,
      }));
    },
    updatePopupData: (popupData: any) => {
      store.update(s => ({ ...s, popupData }));
    },
    // Add other popup-related methods as needed
  };
}

function createContextStore() {
  const store = writable<ContextStore>({
    isOpen: false,
    popupType: '',
    popupData: undefined,
  });

  return {
    subscribe: store.subscribe,
    openPopup: (popupType: string, popupData?: any) => {
      store.update(s => ({ ...s, isOpen: true, popupType, popupData }));
    },
    closePopup: () => {
      store.update(s => ({
        ...s,
        isOpen: false,
        popupType: '',
        popupData: undefined,
      }));
    },
    updatePopupData: (popupData: any) => {
      store.update(s => ({ ...s, popupData }));
    },
    // Add other popup-related methods as needed
  };
}

export const appStore = createAppStore();
export const contextStore = createContextStore();
export const popupStore = createPopupStore();
